progress::-webkit-progress-bar {
    background-color: #ededed;
    border-radius: 5px;
}
progress::-webkit-progress-value {
    background-color: #02B2AF;
    border-radius: 5px 0px 0px 5px;
    transition: width 2s ease-in-out;
}

.prog-bar {
    appearance: none;
}

.yearly-header {
    font-size: 18px;
    color: #5C623D;
    font-weight: bold;
}

.resource-tab-container {
    display: flex;
    justify-content: space-around;
    color:gray;
}

.benefit-container {
    padding: 1em;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.graph-container {
    display:flex;
    
}

.benefit-header-container {
    display: flex;
    align-items: flex-start;
    width: 100%;
    margin-bottom: 7px;
    margin-left: 20px;
    margin-top: 7px;
}

.key {
    display: flex;
    flex-direction: column;
    margin-top:16px;
    width: 100%;
    justify-content: center;
    color: #00000099;
}

.key-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.progress-div {
    margin:5px;
    margin-top:14px;
    display: flex;
    justify-content: space-around;
    color: #00000099;
    align-items: baseline;
    padding-top: 10px;
}

.savings-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top:20px;
}

.savings {
    color: #00000099;

}

.ytd-header {
    color: #00000099;
    display: flex;
    font-weight: 1000;
}

.savings-data {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top:8px;
    gap: 5px;
    margin-left: 10px;
}

.occupant-savings {
    margin: 6px 0 6px 35px;
    color: #00000099;
    text-align: left;
}

.savings-header {
    font-weight: bold;
    color: #00000099;
    font-size: 18px;
    margin-top: 10px;
}

.savings-sub-header {
    color: #00000099;
    margin-bottom: 10px;
}