.hamburger-menu, .building-menu {
    width: 100%;
    height: 100%;
    background-color: white;
    z-index: 1000;
    position: fixed;
    /* top: 102px; */
    left: 0; /* Ensure the menu starts from the left */
    transform: translateX(-100%);
    transition: transform 0.3s ease;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px 0;
}

.hamburger-menu.open, .building-menu.open {
    transform: translateX(0);
    transition: transform 0.3s ease;
}

.building-menu {
    right: 0;
    transform: translateX(+100%);
    padding: 0;
}

.menu {
    list-style: none;
    padding: 0;
    margin: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 15px;
    overflow-y: auto; /* Allow scrolling if content overflows */
}

.menu-item {
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid #ccc;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}

.menu li a, menu button {
    display: flex;
    padding: 15px 0;
    text-decoration: none;
    color: #5C5B49;
    font-size: 20px;
}

.menu-item-header span {
    margin-left: 30px;
}

.menu-item-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    align-items: center;
}

.submenu {
    display: flex;
    padding: 0;
    flex-direction: column;
    width: 100%;
    background-color: rgb(245, 245, 245);
    box-shadow: inset 0 1px 10px rgba(0, 0, 0, 0.1);
}

.menu-item.expanded > .submenu {
    max-height: 500px;
    opacity: 1;
}

.submenu li a {
    padding: 10px;
    font-size: 17px;
}

.depth-1 {
    background-color: rgb(251, 251, 251);
}

.depth-1 li a {
    margin-left: 60px;
}

.menu-item.selected > .menu-item-header > a {
    font-weight: bold;
}

.back-button-container {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: flex-start;
    padding: 10px 0 21px 20px;
    border-bottom: 1px solid #ccc;
    margin-bottom: 15px;
}

.current-category {
    font-size: 21px;
    text-transform: uppercase;
    color: #5C5B49;
    font-weight: bold;
}

.building-item-header  {
    color: #5C5B49;
    font-size: 20px;
}
.building-add li a, .building-add button {
    margin-left: 0;
    font-size: 17px;
    padding: 10px 0 0 0;
    text-align: left;
}
#building-name {
    font-size: 20px;
    padding: 0;
    font-weight: bold;
    text-align:left;
}

.building-item-header {
    padding: 15px 35px;
    display: flex; 
    flex-direction: column;
}

.current-header {
    align-items: center;
    flex-direction: row;
    padding: 15px 20px 15px 35px;
    gap:10px;
}

.current-building {
    border-bottom: 1px solid white;
    /* background-color: rgba(204, 218, 197, 0.626); */
}

.building-add {
    gap: 0;
}

.menu-header {
    width: 100%;
    padding: 10px 35px 10px 35px;
    font-weight: bold;
    font-size: 18px;
    background-color: rgba(204, 218, 197, 0.66);
    color: #5C5B49;
}

@media screen and (min-width: 450px) {

.menu-item {
    align-items: flex-start;
}

.building-item-header {
    padding: 1em 4em;
    justify-content: space-between;
    width:100%;
}

.current-container {
    display: flex;
    flex-direction: column;
}

.menu-header {
    padding: 0.5em 4.25em;
}

}